import logo from './logo.svg';
import './App.css';
import { Canvas, useFrame } from '@react-three/fiber'
import StarField from './components/StarField';

function App() {
    return (
        <div className="App">
            <div className='myCanvas'>
                <Canvas style={{width: '100vw', height: '100vh'}}>
                    <ambientLight intensity={Math.PI * 2} />
                    <StarField position={[0, 0, -10]}/>
                </Canvas>
            </div>
        </div>
    );
}

export default App;
