import React, { startTransition, useRef, useState } from 'react'
import { useFrame, useThree } from '@react-three/fiber'
import { repeatInRange } from '../utils/range'

export default function Star(props) {
    const {clock} = useThree()

    let meshRef = useRef()

    const farClippingDistance = -100
    const speed = 5

    const initialDepth = props.position[2]

    useFrame((state, delta) => {
        meshRef.current.position.z = repeatInRange(farClippingDistance, 0, -initialDepth + clock.getElapsedTime() * speed)
    })

  return (    
    <mesh 
      {...props}
      ref={meshRef}>
      <sphereGeometry  args={[.05, 5, 4]} />
      <meshStandardMaterial color={'white'} />
    </mesh>
  )
}
