
export default function Page(props) {
    return (
        <div className="page">
            <p className="bioGreetingA">
            👋 Hi
            </p>
            <p className="bioGreetingB">
                I'm Aaron
            </p>
            <p className="bioGreetingC">
                Thanks for stopping by
            </p>
            {props.children}
        </div>
    )
}
