function randomRangeWithGap(min, max, gapMin, gapMax) {
    if (gapMin === undefined|| gapMax ==undefined) {
        gapMin = min
        gapMax = min
    }

    let difference = max - min;
    let randomNumber = min + (Math.random() * difference)

    // TOODO: Modify this functionality
    // Some inputs could cause infinite loop without proper guard clauses
    try {
        const inGap = randomNumber <= gapMax && randomNumber >= gapMin
        if (inGap) {
            throw Error()
        }
    } catch(e) {
        return randomRangeWithGap(min, max, gapMin, gapMax)
    }

    return randomNumber
}

function randomRange(min, max) {
    return min + (Math.random() * (max - min));
}

function repeatInRange(min, max, x) {
    return min + x % (max - min)
}

export {
    randomRangeWithGap,
    randomRange,
    repeatInRange
}

