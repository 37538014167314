import React, { startTransition, useRef, useState } from 'react'
import { useFrame } from '@react-three/fiber'
import Star from './Star'
import {randomRangeWithGap, randomRange } from '../utils/range'

export default function StarField(props) {
    let meshRef = useRef()

    const starNumber = 200;
    const farClippingDistance = -100
    let randomPositions = []
    for (let i  = 0; i < starNumber; i++) {
        let randomPosition = []

        randomPosition.push(randomRangeWithGap(-40, 40, -1.2, 1.2)) // X
        randomPosition.push(randomRangeWithGap(-40, 40, -1.2, 1.2)) // Y
        randomPosition.push(randomRange(-100, 0))                    // Z

        randomPositions.push(randomPosition)
    }

    return (
        <>
            {randomPositions.map((randomPosition, index) => <Star key={index} position={randomPosition}></Star>)}
        </>
    )
}
