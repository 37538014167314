import ReactDOM from 'react-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLinked } from '@fortawesome/free-solid-svg-icons'


export default function Bio(props) {
    return (
        <div className="bio">
            <div className="bioRow">
                <div className="bioBox-small">
                    <img className="bioProfileImage" src='profile-sqare-m.jpg' />
                </div>
                <div className="bioBox-small">
                    <div className="bioRow">
                    <p>Aaron Dabney</p>
                    </div>
                    <div className="bioRow">
                    <p>Santa Rosa, CA</p>
                    </div>
                    <FontAwesomeIcon icon="fa-brands fa-linkedin-in" />
      
                  
                            <p><a href="https://github.com/AaronDabney">GitHub</a></p>
                  
    
                            <p><a href="https://www.linkedin.com/in/aaron-dabney-57481625a">LinkdIn</a></p>
    
                </div>
                <div className="bioBox-small">
                    <p>TypeScript</p>
                    <p>JavaScript</p>
                    <p>Node.js</p>
                    <p>React</p>
                    <p>Python</p>
                    <p>Git</p>
                    <p>AWS</p>
                </div>
            </div>
        </div>
    )
}